import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";

import { Avatar, Box, Button } from "@mui/material";
import { useStore } from "@nanostores/react";
import { setUser as setSentryUser } from "@sentry/browser"; // aliased due to AppContext.setUser()
import AvatarMenu from "common/components/AvatarMenu";
import FullPageSpinner from "common/components/FullPageSpinner";
import { user as appUser } from "common/stores/UserStore";
import { IUser } from "common/utils/UserUtils";
import AuthClient from "modules/facade/AuthClient";
import DefaultAvatar from "public/images/default_avatar.png";
import { useEffect, useState } from "react";

export const publicPaths = [
	"/login",
	"/login/authFail",
	"/notFound",
	"/logout",
];
// NOTE: There are routes that may require generic authentication but no specific role authorizations
// these types of routes do not need to be listed in either publicRoutes or guardedRoutes

interface RouteConfig {
	path: string;
	enablingEndorsements: string[];
	enablingPermissions: string[];
}

/**
 * Configuration for authorization for routes within this app.
 * `path` in guarded routes will match in a startsWith fashion, so to secure a sub route different from a base route include both.
 * If a user has EITHER 1 or more enabling endorsement OR 1 or more enabling permission for a route they will be granted access.
 * At present this has to do with the fact that not all users have organizations, which behind the scenes are the entities that
 * impart endorsements to a user.
 * NOTE: roles on the token/cookie are aka permissions in auth0 - where roles are collections of permissions.
 */
const guardedRoutes: Array<RouteConfig> = [
	{
		path: "/survey/surveyProgressDashboard",
		enablingEndorsements: ["survey:surveyor"],
		enablingPermissions: ["give:survey", "survey:surveyor"],
	},
	{
		path: "/survey",
		enablingEndorsements: ["survey:surveyee", "survey:surveyor"],
		enablingPermissions: ["take:survey", "give:survey"],
	},
	{
		path: "/admin/api-keys",
		enablingEndorsements: [],
		enablingPermissions: ["read:apikey"],
	},
	{
		path: "/admin",
		enablingEndorsements: [],
		enablingPermissions: ["write:organization"],
	},
	{
		path: "/prototype",
		enablingEndorsements: [""],
		enablingPermissions: ["optera:admin"],
	},
	{
		path: "/supplyChainManager",
		enablingEndorsements: ["supply_chain_insights:brand"],
		enablingPermissions: ["optera:admin"],
	},
	{
		path: "/esgInsights/ng",
		enablingEndorsements: ["esg_insights:brand"],
		enablingPermissions: [],
	},
	{
		path: "/esgInsights",
		enablingEndorsements: ["sam:brand"],
		enablingPermissions: [],
	},
	{
		path: "/supplyChain",
		enablingEndorsements: ["supplychain:brand", "supplychain:supplier"],
		enablingPermissions: [],
	},
	{
		path: "/esgInsightsUpload/upload",
		enablingEndorsements: [],
		enablingPermissions: ["optera:admin"],
	},
	{
		path: "/esgInsightsUpload",
		enablingEndorsements: [],
		enablingPermissions: [],
	},
	{
		path: "/legacyOverview",
		enablingEndorsements: [
			"sam:brand",
			"supplychain:brand",
			"supplychain:supplier",
		],
		enablingPermissions: ["optera:admin"],
	},
];

/**
 * Use `guardedRoutes` configuration object to check if a given path is authorized for a given user
 */
export const checkUsersRouteAccess = (path: string, userInfo: IUser) => {
	// using find and startsWith like this means more specific routes (sub routes) should be listed before base routes in the guardedRoutes object
	const protectedRouteConfig = guardedRoutes.find((routeConfig) =>
		path.startsWith(routeConfig.path)
	);
	if (!protectedRouteConfig) {
		// This works off assumption that public or unguarded routes would not be listed in guardedRoutes config.
		// Depending on frequency of assessing access of public routes might be faster
		// to do publicPaths.includes(path) check before guardedRoutes.find(...)
		return true;
	}
	return (
		userInfo.endorsements?.some((endorsement) =>
			protectedRouteConfig.enablingEndorsements.includes(endorsement)
		) ||
		userInfo.roles?.some((role) =>
			protectedRouteConfig.enablingPermissions.includes(role)
		)
	);
};

/**
 * Intended to check if a user should be automatically routed off to flagstaff.
 * If the user has any endorsement or permission/role that could be used in this app, then they can stay.
 */
export const checkGeneralAccess = (userInfo: IUser) => {
	const allExpectedAppEndorsements = guardedRoutes.flatMap(
		(route) => route.enablingEndorsements
	);
	const allExpectedAppPermissions = guardedRoutes.flatMap(
		(route) => route.enablingPermissions
	);
	return (
		userInfo.endorsements?.some((endorsement) =>
			allExpectedAppEndorsements.includes(endorsement)
		) ||
		userInfo.roles?.some((role) => allExpectedAppPermissions.includes(role))
	);
};

export function NopeBox({
	title,
	children,
	user,
}: {
	title: string | React.ReactElement;
	children?: React.ReactElement;
	user?: IUser;
}) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box
			m={6}
			sx={{
				display: "grid",
				gridTemplateRows: "repeat(3, 1fr)",
				gridTemplateColumns: "1fr 60px",
				gap: 3,
			}}
		>
			<h3>{title}</h3>
			<Avatar
				src={user?.picture || DefaultAvatar.src}
				data-testid="Avatar"
				onClick={handleAvatarClick}
			/>
			{user ? (
				<AvatarMenu
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					organization={user.organization_id.toString()}
				/>
			) : undefined}
			{children}
			<p></p>
			<Link href="/" passHref className="link-text1">
				Home
			</Link>
		</Box>
	);
}

export default function RouteGuard({ children }) {
	const router = useRouter();
	const $user = useStore(appUser);

	// utilizes preload in _document.tsx
	const {
		data: userData,
		error,
		isLoading,
	} = useSWR("/auth/whoami", AuthClient.whoami, {
		shouldRetryOnError: false,
		revalidateOnFocus: false,
	});
	const errorDataDetail = error?.response?.detail;

	useEffect(() => {
		if (isLoading) return;
		if (errorDataDetail?.error == "Authentication Error") {
			// redirection handled elsewhere, here just clear out the userStore since it is no longer a validly authenticated user
			appUser.set({
				username: "",
				gateway: "",
				organization: "",
				organization_id: "",
				deprecated_flagstaff_org_id: null,
				endorsements: [],
				roles: [],
				sub: "",
				exp: "",
				picture: "",
			} as IUser);
		} else if (userData) {
			appUser.set(userData);
			setSentryUser(userData);
		}
	}, [errorDataDetail, isLoading, userData]);

	if (isLoading) {
		return <FullPageSpinner />;
	}

	const path = router.asPath.split("?")[0];

	// useful for debugging Next.js issues re persistent appContext.
	// console.log("route Guard check", user);

	// 401 response from fetch API isn't thrown as an actual error, HTTP call and response was technically successful.
	// 401 is also handled in MonarchClient so isn't thrown as axios typically would.
	// If not using swr, might look at response status code, but swr (which has other advantages) swallows that up,
	// so just looking at the content of response data will do
	if (errorDataDetail) {
		// public paths only need to be checked if auth fails.
		// this makes it so that even public paths will have user set in AppContext if the user was authenticated
		if (publicPaths.includes(path)) {
			return children;
		}
		// console.error('authentication error, move along please: ', data.detail.error);
		if (errorDataDetail.error == "Authentication Error") {
			// because MonarchClient handles router.push on 401 no need to redo that here
			return null;
		}

		return (
			<NopeBox title="Unexpected authentication error">
				<p className="body1">
					You may need to re-login:{" "}
					<Button variant="outlined" onClick={() => router.push("/login")}>
						Go to login
					</Button>
				</p>
			</NopeBox>
		);
	}

	// if we've previously authenticated, then only need to check for route authorization
	// this prevents unnecessary flashes on page transitions. NOTE, this assumes a persistent appContext between
	// page navigation so be sure to use Next/Link to get that
	if ($user.username) {
		if (checkUsersRouteAccess(path, $user)) {
			return children;
		} else {
			return (
				<NopeBox title="Access Restricted" user={$user}>
					<p className="body1">
						You are missing necessary roles or endorsements. Contact Optera
						support for assistance
					</p>
				</NopeBox>
			);
		}
	}

	if (userData) {
		if (checkGeneralAccess(userData)) {
			return children;
		} else {
			<NopeBox title="Access Denied" user={userData}>
				<p className="body1">
					You are missing necessary roles or endorsements. Contact Optera
					support for assistance
				</p>
			</NopeBox>;
		}
	}

	// renders a progress spinner in place of page during initial whoami check
	return <FullPageSpinner />;
}
