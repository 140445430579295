import { IconProps } from "./IconProps";

function InventoryIcon({
	width = "24",
	height = "24",
	color = "#223234",
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 7C5 6.44772 5.44772 6 6 6H16C16.5523 6 17 6.44772 17 7C17 7.55228 16.5523 8 16 8H6C5.44772 8 5 7.55228 5 7Z"
				fill={color}
			/>
			<path
				d="M5 12C5 11.4477 5.44772 11 6 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H6C5.44772 13 5 12.5523 5 12Z"
				fill={color}
			/>
			<path
				d="M5 17C5 16.4477 5.44772 16 6 16H10C10.5523 16 11 16.4477 11 17C11 17.5523 10.5523 18 10 18H6C5.44772 18 5 17.5523 5 17Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.6746 16.1263C23.0775 16.504 23.098 17.1368 22.7203 17.5397L18.1063 22.462C17.6137 22.9875 16.7801 22.989 16.2856 22.4654L13.97 20.0136C13.5908 19.6121 13.6089 18.9792 14.0104 18.6C14.4119 18.2208 15.0448 18.2388 15.424 18.6404L17.1923 20.5126L21.2611 16.172C21.6388 15.769 22.2716 15.7486 22.6746 16.1263Z"
				fill={color}
			/>
			<path
				d="M17 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H12V23H5C2.79086 23 1 21.2091 1 19V5C1 2.79086 2.79086 1 5 1H17C19.2091 1 21 2.79086 21 5V13H19V5C19 3.89543 18.1046 3 17 3Z"
				fill={color}
			/>
		</svg>
	);
}
export default InventoryIcon;
