import { useStore } from "@nanostores/react";
import LeftRail, { LeftRailProps } from "common/components/Nav/LeftRail";
import OpteraAppBar, {
	OpteraAppBarProps,
} from "common/components/Nav/OpteraAppBar";
import { railExpandedStore } from "common/stores/NavigationRailStore";
import MuiXLicense from "common/utils/MuiXLicense";
import * as echarts from "echarts";
import { useEffect } from "react";
import chartTheme from "styles/chartTheme.json"; // vitest is lost on importing this from styles aliased path

interface LayoutProps {
	children: React.ReactNode;
	appBarProps?: OpteraAppBarProps;
	leftRailProps?: LeftRailProps;
}

export default function OpteraLayout({
	children,
	appBarProps,
	leftRailProps,
}: LayoutProps) {
	const $railExpanded = useStore(railExpandedStore);

	useEffect(() => {
		if (leftRailProps?.initialExpanded != null) {
			railExpandedStore.set(leftRailProps.initialExpanded);
		}
	}, [leftRailProps?.initialExpanded]);

	echarts.registerTheme("chartTheme", chartTheme);

	return (
		<div id="opteraLayout-wrapper" className={$railExpanded ? "nav-wide" : ""}>
			<OpteraAppBar {...appBarProps} />
			<LeftRail {...leftRailProps} />
			<main
				className={$railExpanded ? "main-rail-expanded" : "main-rail-collapsed"}
			>
				{children}
			</main>
			<MuiXLicense />
		</div>
	);
}
