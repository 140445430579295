import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/material/themeCssVarsAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import LightBulbIcon from "common/components/Icons/LightbulbIcon";

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		delete: true;
	}
}
declare module "@mui/material/styles" {
	interface TypographyVariants {
		xxl: true;
		xl: true;
		large: true;
		small: true;
		chip: true;
	}
	interface TypographyVariantsOptions {
		xxl: React.CSSProperties;
		xl: React.CSSProperties;
		large: React.CSSProperties;
		small: React.CSSProperties;
		chip: React.CSSProperties;
	}
	interface Palette {
		scope1: Palette["primary"];
		scope2: Palette["primary"];
		scope3: Palette["primary"];
		core: Palette["primary"];
		chart: Palette["primary"];
		lineMetric: Palette["primary"];
		linkText: Palette["primary"];
		onLightText: Palette["primary"];
		onDarkText: Palette["primary"];
		opteraBackground: Palette["primary"];
		outlier: Palette["primary"];
		optera: Palette["primary"];
		chipColors: Palette["primary"];
		chipBgColors: Palette["primary"];
		tooltip: Palette["primary"];
		menuItemSelectedBackground: Palette["primary"];
	}
	interface PaletteOptions {
		scope1: PaletteOptions["primary"];
		scope2: PaletteOptions["primary"];
		scope3: PaletteOptions["primary"];
		linkText: PaletteOptions["primary"];
		onLightText: PaletteOptions["primary"];
		onDarkText: PaletteOptions["primary"];
		core: PaletteOptions["primary"];
		chart: PaletteOptions["primary"];
		lineMetric: PaletteOptions["primary"];
		opteraBackground: PaletteOptions["primary"];
		outlier: PaletteOptions["primary"];
		optera: PaletteOptions["primary"];
		chipColors: PaletteOptions["primary"];
		chipBgColors: PaletteOptions["primary"];
		tooltip: PaletteOptions["primary"];
		progressBar: PaletteOptions["primary"];
		menuItemSelectedBackground: PaletteOptions["primary"];
	}
	// module augmentation for the theme to accept different values
	interface PaletteColor {
		primary?: string;
		secondary?: string;
		disabled?: string;
		lighter?: string;
		resting?: string;
		containedHover?: string;
		outlinedHover?: string;
		colors?: string[];
		positive?: string;
		negative?: string;
		neutral?: string;
		background?: string;
		lightGreen?: string;
		darkGreen?: string;
		lightGrey?: string;
		brandDark?: string;
		jade?: string;
		green?: string;
		blue?: string;
		orange?: string;
		yellow?: string;
		indigo?: string;
		grey?: string;
		red?: string;
		pink?: string;
		medium?: string;
		empty?: string;
	}
	interface SimplePaletteColorOptions {
		primary?: string;
		secondary?: string;
		lighter?: string;
		resting?: string;
		containedHover?: string;
		outlinedHover?: string;
		colors?: string[];
		darkGreen?: string;
		lightGreen?: string;
		disabled?: string;
		positive?: string;
		negative?: string;
		lightGrey?: string;
		brandDark?: string;
		neutral?: string;
		background?: string;
		jade?: string;
		grey?: string;
		green?: string;
		blue?: string;
		orange?: string;
		yellow?: string;
		indigo?: string;
		red?: string;
		pink?: string;
		medium?: string;
		empty?: string;
		opaque?: string;
	}
}
declare module "@mui/material/Paper" {
	interface PaperPropsVariantOverrides {
		ocData: true;
		ocGrey: true;
	}
}
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		secondaryHyperlink: true;
		xxl: true;
		xl: true;
		large: true;
		small: true;
		chip: true;
	}
}

// Extend AlertProps to include the new "severity"
declare module "@mui/material/Alert" {
	interface AlertPropsColorOverrides {
		recommendation: true;
		neutral: true;
	}
}

const lightgrey = "rgba(104, 104, 104, 0.08)";
const inactive = "rgba(0, 0 , 0, 0.26)";

export const themeOptions = {
	spacing: 8,
	palette: {
		primary: {
			50: "rgba(248, 251, 244, 1)",
			75: "rgba(240, 252, 235, 1)",
			100: "rgba(224, 252, 213, 1)",
			200: "rgba(172, 228, 149, 1)",
			300: "rgba(122, 186, 94, 1)",
			400: "rgba(77, 129, 55, 1)",
			500: "rgba(39, 82, 20, 1)",
			600: "rgba(25, 50, 51, 1)",
			700: "rgba(12, 23, 23, 1)",
			main: "rgba(25, 50, 51, 0.9)",
			light: "rgba(176, 245, 147, 0.2)",
			dark: "#275214",
			contrastText: "#FFFFFF",
		},
		text: {
			primary: "rgba(14, 26, 27, 0.87)",
			secondary: "rgba(14, 26, 27, 0.6)",
		},
		secondary: {
			50: "rgba(255, 255, 255, 1)",
			100: "rgba(251, 251, 251, 1)",
			150: "rgba(242, 245, 245, 1)",
			200: "rgba(226, 233, 233, 1)",
			300: "rgba(198, 209, 210, 1)",
			400: "rgba(157, 173, 174, 1)",
			500: "rgba(102, 121, 122, 1)",
			600: "rgba(58, 77, 78, 1)",
			700: "rgba(34, 50, 52, 1)",
			800: "rgba(14, 26, 27, 1)",
			main: "rgba(14, 26, 27, 1)",
			resting: "rgba(14, 26, 27, 0.4)",
			containedHover: "rgba(255, 255, 255, 1)",
			light: "rgba(14, 26, 27, 0.54)",
			dark: "rgba(14, 26, 27, 0.7)",
			contrastText: "rgba(255, 255, 255, 1)",
		},
		error: {
			50: "#FEF2F3",
			100: "#FDCED0",
			200: "#FB9DA2",
			300: "#F47178",
			400: "rgba(199, 23, 32, 1)",
			500: "#A11219",
			600: "#730D12",
			700: "#2E0507",
			main: "rgba(199, 23, 32, 1)",
			light: "rgba(199, 23, 32, 0.08)",
			lighter: "#FEF2F3",
			dark: "rgba(115, 13, 18, 0.8)",
			contrastText: "#FFFFFF",
		},
		warning: {
			50: "#FFF6EB",
			100: "#FEDEB3",
			200: "#FEC171",
			300: "#F79009",
			400: "#B54708",
			500: "#843406",
			600: "#532104",
			700: "#250E02",
			main: "rgba(181, 71, 8, 0.8)",
			light: "rgba(247, 144, 9, 0.08)",
			dark: "rgba(132, 52, 6, 0.8)",
			contrastText: "#FFFFFF",
		},
		info: {
			50: "#EBF3FF",
			100: "#CDE1FE",
			200: "#96C1FD",
			300: "#569BFB",
			400: "#1570EF",
			500: "#1450B8",
			600: "#14346B",
			700: "#0A1934",
			main: "#1570EF",
			light: "#96C1FD",
			lighter: "#EBF3FF",
			dark: "#14346B",
			contrastText: "#FFFFFF",
		},
		success: {
			50: "#ECFDF3",
			100: "#D4F7E2",
			200: "#7DE8B8",
			300: "#33CC87",
			400: "#07834B",
			500: "#00663C",
			600: "#033B24",
			700: "#011C12",
			main: "#07834B",
			light: "#4DB6AC",
			lighter: "#ECFDF3",
			dark: "#00796B",
			contrastText: "#FFFFFF",
		},
		core: {
			main: "#FFFFFF",
			darkGreen: "#193233",
			lightGreen: "#E0FCD5",
		},
		opteraBackground: {
			main: "#FFFFFF",
			lightGreen: "#F8FBF4",
			neutral: "#0E1A1B",
			brandDark: "#193233",
			lightGrey: "#FBFBFB",
		},
		action: {
			active: "rgba(14, 26, 27, 0.54)",
			hover: "rgba(14, 26, 27, 0.04)",
			selected: "rgba(14, 26, 27, 0.08)",
			disabled: "rgba(14, 26, 27, 0.26)",
			disabledBackground: "rgba(14, 26, 27, 0.12)",
		},
		onLightText: {
			main: "rgba(14, 26, 27, 0.87)",
			secondary: "rgba(14, 26, 27, 0.6)",
			disabled: "rgba(14, 26, 27, 0.38)",
		},
		onDarkText: {
			main: "rgba(255, 255, 255, 0.99)",
			secondary: "rgba(255, 255, 255, 0.68)",
			disabled: "rgba(255, 255, 255, 0.35)",
		},
		linkText: {
			main: "rgba(14, 26, 27, 0.87)",
			secondary: "rgba(14, 26, 27, 0.6)",
		},
		progressBar: {
			main: "#F5F5F5",
		},
		menuItemSelectedBackground: {
			main: "rgba(14, 26, 27, 0.08)",
			secondary: "rgba(14, 26, 27, 0.04)",
		},
		tooltip: {
			main: "#0E1A1B",
			contrastText: "rgba(255, 255, 255, 0.99)",
		},
		chart: {
			main: "#00BCD4",
			colors: [
				"#80DEEA",
				"#4DB6AC",
				"#FFA000",
				"#FFC107",
				"#F06292",
				"#BA68C8",
			],
		},
		lineMetric: {
			main: "#00BCD4",
			positive: "#00BCD4",
			negative: "#E91E63",
			neutral: "rgba(0, 0, 0, 0.54)",
		},
		outlier: {
			main: "#E91E63",
			secondary: "#E91E6308",
		},
		optera: {
			main: "#00BCD4",
			jade: "#005151",
			green: "#009681",
			blue: "#67D2DF",
			orange: "#ED8B00",
			yellow: "#FFC72C",
		},
		chipColors: {
			main: "#00BCD4",
			yellow: "#B54708",
			indigo: "#3538CD",
			grey: "#344054",
			green: "#027A48",
			red: "#B42318",
			orange: "#C4320A",
			blue: "#175CD3",
			pink: "#C11574",
		},
		chipBgColors: {
			main: "#00BCD4",
			yellow: "#FFFAEB",
			indigo: "#EEF4FF",
			grey: "#F2F4F7",
			green: "#ECFDF3",
			red: "#FEF3F2",
			orange: "#FFF6ED",
			blue: "#EFF8FF",
			pink: "#FDF2FA",
		},
		divider: "rgba(0,0,0,0.12)",
		scope1: {
			// teal
			main: "rgba(11, 161, 177, 1)",
			50: "rgba(242, 254, 255, 1)",
			100: "rgba(227, 250, 252, 1)",
			200: "rgba(124, 230, 242, 1)",
			300: "rgba(86, 211, 225, 1)",
			400: "rgba(11, 161, 177, 1)",
			500: "rgba(22, 111, 121, 1)",
			600: "rgba(2, 73, 80, 1)",
			700: "rgba(1, 32, 35, 1)",
		},
		scope2: {
			// chartreuse
			main: "rgba(192, 223, 91, 1)",
			50: "rgba(251, 255, 236, 1)",
			100: "rgba(237, 252, 188, 1)",
			200: "rgba(215, 242, 123, 1)",
			300: "rgba(192, 223, 91, 1)",
			400: "rgba(155, 187, 49, 1)",
			500: "rgba(98, 121, 22, 1)",
			600: "rgba(62, 80, 2, 1)",
			700: "rgba(27, 35, 1, 1)",
		},
		scope3: {
			// indigo
			main: "rgba(5, 47, 117, 1)",
			50: "rgba(247, 248, 255, 1)",
			100: "rgba(240, 241, 255, 1)",
			200: "rgba(217, 221, 252, 1)",
			300: "rgba(168, 175, 240, 1)",
			400: "rgba(62, 74, 178, 1)",
			500: "rgba(46, 57, 153, 1)",
			600: "rgba(35, 43, 117, 1)",
			700: "rgba(6, 9, 37, 1)",
		},
		purple: {
			50: "rgba(254, 247, 255, 1)",
			100: "rgba(251, 230, 254, 1)",
			200: "rgba(243, 198, 251, 1)",
			300: "rgba(225, 144, 239, 1)",
			400: "rgba(160, 68, 168, 1)",
			500: "rgba(115, 32, 92, 1)",
			600: "rgba(76, 42, 58, 1)",
			700: "rgba(35, 8, 20, 1)",
		},
		tangerine: {
			100: "rgba(255, 226, 172, 1)",
		},
		skyBlue: {
			100: "rgba(207, 243, 254, 1)",
		},
		honeyYellow: {
			100: "rgba(255, 241, 200, 1)",
		},
	},
	typography: {
		fontFamily: "Inter",
		h1: {
			fontFamily: "Inter",
			fontSize: "96px",
			fontWeight: 700,
			lineHeight: "112px",
			letterSpacing: "-1.5px",
		},
		h2: {
			fontFamily: "Inter",
			fontSize: "60px",
			fontWeight: 700,
			lineHeight: "72px",
			letterSpacing: "-0.5px",
		},
		h3: {
			fontFamily: "Inter",
			fontSize: "48px",
			fontWeight: 700,
			lineHeight: "56px",
		},
		h4: {
			fontFamily: "Inter",
			fontSize: "34px",
			fontWeight: 700,
			lineHeight: "42px",
		},
		h5: {
			fontFamily: "Inter",
			fontSize: "24px",
			fontWeight: 500,
			lineHeight: "32px",
		},
		h6: {
			fontFamily: "Inter",
			fontSize: "20px",
			fontWeight: 500,
			lineHeight: "26.6px",
		},
		body1: {
			fontFamily: "Inter",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: "24px",
		},
		body2: {
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "20px",
			letterSpacing: "0.15px",
		},
		subtitle1: {
			fontFamily: "Inter",
			fontSize: "16px",
			fontWeight: 700,
			lineHeight: "21.3px",
		},
		subtitle2: {
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 700,
			lineHeight: "22px",
			letterSpacing: "0.1px",
		},
		overline: {
			fontFamily: "Inter",
			fontSize: "12px",
			fontWeight: 700,
			lineHeight: "31.9px",
			textTransform: "none" as const,
		},
		caption: {
			fontFamily: "Inter",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "15.6px",
		},
		button: {
			textTransform: "none" as const,
		},
		chip: {
			fontFamily: "Inter",
			fontSize: "13px",
			fontWeight: 600,
			lineHeight: "17.94px",
			textTransform: "none" as const,
		},
		small: {
			textTransform: "none" as const,
			fontSize: "13px",
		},
		large: {
			fontFamily: "Inter",
			fontSize: "34px",
			fontWeight: 600,
			lineHeight: "42px",
		},
		xl: {
			fontFamily: "Inter",
			fontSize: "48px",
			fontWeight: 700,
			lineHeight: "56px",
		},
		xxl: {
			fontFamily: "Inter",
			fontSize: "60px",
			fontWeight: 700,
			lineHeight: "72px",
			letterSpacing: "-0.5px",
		},
	},
	shape: {
		borderRadius: 8,
	},
	cssVariables: {
		cssVarPrefix: "oc",
		disableCssColorScheme: true, // we're not trying to have multiple color schemes for now
	},
};

const opteraTheme = createTheme(themeOptions);

opteraTheme.components = {
	MuiAvatar: {
		styleOverrides: {
			root: {
				backgroundColor: "transparent",
				fontSize: 48,
			},
		},
	},
	MuiGrid: {
		defaultProps: { spacing: { sm: 2, md: 3, lg: 4 } },
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				color: `${opteraTheme.palette.text.secondary}`,
				marginRight: "2px",
				padding: "9px",
			},
		},
		variants: [
			{
				props: { checked: true, color: "primary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen} !important`, // Button base overriding color
					"&:hover": {
						background: `${opteraTheme.palette.primary[200]}`,
					},
				},
			},
			{
				props: { indeterminate: true, color: "primary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen} !important`, // Button base overriding color
					"&:hover": {
						background: `${opteraTheme.palette.primary.light}`,
					},
				},
			},
			{
				props: { checked: true, indeterminate: false, color: "secondary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen} !important`, // Button base overriding color
					"&:hover": {
						background: lightgrey,
					},
				},
			},
			{
				props: { checked: false, indeterminate: true, color: "secondary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen} !important`, // Button base overriding color
					background: "rgba(14, 26, 27, 0.6)",
					"&:hover": {
						background: lightgrey,
					},
				},
			},
			{
				props: { checked: true, disabled: true },
				style: {
					color: `${opteraTheme.palette.text.disabled}`,
				},
			},
		],
	},
	MuiChip: {
		styleOverrides: {
			root: {
				"& .MuiChip-iconSmall": {
					marginLeft: "8px",
					marginRight: "unset",
				},
			},
		},
	},
	MuiRadio: {
		styleOverrides: {
			root: {
				marginRight: "2px",
				padding: "9px",
			},
		},
		variants: [
			{
				props: { checked: true, color: "primary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen}`,
					"&:hover": {
						background: `${opteraTheme.palette.primary.light}`,
					},
				},
			},
			{
				props: { checked: false, color: "primary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen}`,
					"&:hover": {
						background: lightgrey,
					},
				},
			},
			{
				props: { checked: true, color: "secondary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen}`,
					"&:hover": {
						background: lightgrey,
					},
				},
			},
			{
				props: { checked: false, color: "secondary" },
				style: {
					color: `${opteraTheme.palette.core.darkGreen}`,
					"&:hover": {
						background: lightgrey,
					},
				},
			},
			{
				props: { disabled: true },
				style: {
					color: `${opteraTheme.palette.text.disabled}`,
				},
			},
		],
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				color: `${opteraTheme.palette.text.secondary}`,
				margin: "3px 0 0",
			},
		},
	},
	MuiFormControlLabel: {
		styleOverrides: {
			root: {
				color: `${opteraTheme.palette.text.primary}`,
			},
		},
		variants: [
			{
				props: { color: "error" },
				style: {
					color: `${opteraTheme.palette.error.main}`,
				},
			},
		],
	},
	MuiFormLabel: {
		styleOverrides: {
			root: {
				color: `${opteraTheme.palette.text.secondary}`,
			},
		},
		variants: [
			{
				props: { color: "error" },
				style: {
					color: `${opteraTheme.palette.error.main}`,
				},
			},
		],
	},
	MuiButton: {
		variants: [
			{
				props: { variant: "outlined", color: "primary" },
				style: {
					border: `1px solid ${opteraTheme.palette.primary.main}`,
					color: `${opteraTheme.palette.primary.main}`,
					"&:hover": {
						backgroundColor: `${opteraTheme.palette.primary.light}`,
					},
				},
			},
			{
				props: { variant: "text", color: "primary" },
				style: {
					color: `${opteraTheme.palette.primary.main}`,
					"&:hover": {
						backgroundColor: `${opteraTheme.palette.primary.light}`,
					},
				},
			},
			{
				props: { variant: "outlined", color: "secondary" },
				style: {
					border: `1px solid ${opteraTheme.palette.secondary.resting}`,
					color: `${opteraTheme.palette.text.primary}`,
					"&:hover": {
						backgroundColor: "transparent",
						border: `1px solid ${opteraTheme.palette.secondary.dark}`,
					},
				},
			},
			{
				props: { variant: "text", color: "secondary" },
				style: {
					color: `${opteraTheme.palette.text.primary}`,
				},
			},
			{
				props: { variant: "outlined", color: "error" },
				style: {
					border: `1px solid ${opteraTheme.palette.error.dark}`,
					color: "#A11219",
					"&:hover": {
						backgroundColor: `${opteraTheme.palette.error.light}`,
					},
				},
			},
			{
				props: { variant: "text", color: "error" },
				style: {
					color: "#A11219",
					"&:hover": {
						backgroundColor: `${opteraTheme.palette.error.light}`,
					},
				},
			},
			{
				props: { size: "small" },
				style: {
					padding: "5px 16px",
					fontWeight: 600,
				},
			},
			{
				props: { size: "large" },
				style: {
					fontWeight: 600,
				},
			},
		],
		defaultProps: {
			disableRipple: true,
			disableElevation: true,
		},
		styleOverrides: {
			sizeSmall: {
				fontFamily: "Inter",
				fontWeight: 800,
				fontSize: "13px",
				lineHeight: "22px",
				padding: "4px 10px 4px 10px",
			},
			sizeMedium: {
				fontFamily: "Inter",
				fontWeight: 600,
				fontSize: "14px",
				lineHeight: "24px",
				padding: "6px 16px 6px 16px",
			},
			sizeLarge: {
				fontFamily: "Inter",
				fontWeight: "bold",
				fontSize: "15px",
				lineHeight: "26px",
				padding: "8px 16px 8px 16px",
			},
		},
	},
	MuiToggleButton: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: {
				borderRadius: 4,
				borderColor: opteraTheme.palette.secondary[300],
				backgroundColor: opteraTheme.palette.opteraBackground.lightGrey,
				"&:hover": {
					backgroundColor: opteraTheme.palette.core.lightGreen,
					color: opteraTheme.palette.text.primary,
				},
				"&.Mui-selected": {
					borderColor: opteraTheme.palette.primary[600],
					backgroundColor: opteraTheme.palette.opteraBackground.main,
					zIndex: 2,
				},
				"&.Mui-selected:hover": {
					backgroundColor: opteraTheme.palette.opteraBackground.main,
				},
				paddingLeft: opteraTheme.spacing(2.75),
				paddingRight: opteraTheme.spacing(2.75),
			},
		},
	},
	MuiCard: {
		variants: [
			{
				props: { variant: "ocData" },
				style: {
					borderRadius: 16,
					padding: 24,
					overflow: "visible",
				},
			},
			{
				props: { variant: "ocGrey" },
				style: {
					borderRadius: 16,
					padding: 24,
					backgroundColor: opteraTheme.palette.opteraBackground.lightGrey,
				},
			},
		],
		styleOverrides: {
			root: {
				border: `1px solid ${opteraTheme.palette.secondary[200]}`,
				borderRadius: 8,
				background: opteraTheme.palette.opteraBackground.main,
				boxShadow: "none",
			},
		},
	},
	MuiAlert: {
		variants: [],
		defaultProps: {
			iconMapping: {
				recommendation: <LightBulbIcon />, // Default icon for custom severity
				neutral: <InfoOutlinedIcon fontSize="inherit" />, // Default icon for custom severity
			},
		},
		styleOverrides: {
			root: ({ ownerState }) => {
				let customStyles: any = { borderRadius: 4 };
				if (ownerState.severity === "recommendation") {
					customStyles = {
						...customStyles,
						backgroundColor: opteraTheme.palette.primary[50],
						color: opteraTheme.palette.primary[500],
						"& .MuiAlertTitle-root": {
							color: opteraTheme.palette.primary.main,
						},
					};
					if (ownerState.variant === "outlined") {
						customStyles.borderColor = opteraTheme.palette.primary[400];
						customStyles.borderWidth = 1;
						customStyles.borderStyle = "solid";
					} else if (ownerState.variant === "filled") {
						customStyles.backgroundColor = opteraTheme.palette.primary[500];
						customStyles.color = opteraTheme.palette.onDarkText.main;
					}
				} else if (ownerState.severity === "neutral") {
					customStyles = {
						...customStyles,
						backgroundColor: opteraTheme.palette.secondary[150],
						color: opteraTheme.palette.secondary.main,
						"& .MuiAlertTitle-root": {
							color: opteraTheme.palette.secondary.main,
						},
					};
					if (ownerState.variant === "outlined") {
						customStyles.borderColor = opteraTheme.palette.onLightText.main;
						customStyles.borderWidth = 1;
						customStyles.borderStyle = "solid";
					} else if (ownerState.variant === "filled") {
						customStyles.backgroundColor = opteraTheme.palette.secondary[500];
						customStyles.color = opteraTheme.palette.onDarkText.main;
					}
				}
				return customStyles;
			},
			standard: {
				color: opteraTheme.palette.text.primary,
			},
			standardError: {
				backgroundColor: opteraTheme.palette.error[50],
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.error.main,
				},
			},
			standardInfo: {
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.info.main,
				},
			},
			standardSuccess: {
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.success.main,
				},
			},
			standardWarning: {
				backgroundColor: opteraTheme.palette.warning[50],
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.warning.main,
				},
			},
			outlined: {
				color: opteraTheme.palette.text.primary,
			},
			outlinedError: {
				borderColor: opteraTheme.palette.error[100],
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.error.main,
				},
			},
			outlinedInfo: {
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.info.main,
				},
			},
			outlinedSuccess: {
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.success.main,
				},
			},
			outlinedWarning: {
				borderColor: opteraTheme.palette.warning[100],
				"& .MuiAlertTitle-root": {
					color: opteraTheme.palette.warning.main,
				},
			},
		},
	},
	MuiAlertTitle: {
		styleOverrides: {
			root: {
				fontWeight: 700,
			},
		},
	},
	MuiSwitch: {
		styleOverrides: {
			root: {
				width: 44,
				height: 24,
				padding: 0,
				marginRight: 8,

				"&:focus-within": {
					boxShadow: "0px 0px 0px 4px #E0FCD5",
					borderRadius: "12px",
				},

				"&:hover": {
					"& .MuiSwitch-track": {
						backgroundColor: "#D0D5DD",
					},
					"& .MuiSwitch-switchBase": {
						"&.Mui-checked": {
							"& + .MuiSwitch-track": {
								backgroundColor: "#275214",
							},
						},
					},
				},
				"& .MuiSwitch-switchBase": {
					padding: 0,
					margin: 2,
					transitionDuration: "300ms",

					"&.Mui-checked": {
						transform: "translateX(20px)",
						color: "#fff",
						"& + .MuiSwitch-track": {
							backgroundColor: `${opteraTheme.palette.primary.main}`,
							opacity: 1,
							border: 0,
						},
					},

					"&.Mui-disabled .MuiSwitch-thumb": {
						backgroundColor: opteraTheme.palette.action.disabledBackground,
						boxShadow:
							"0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)",
					},
					"&.Mui-disabled + .MuiSwitch-track": {
						backgroundColor: opteraTheme.palette.action.disabledBackground,
						opacity: "12%",
					},
				},
				"& .MuiSwitch-thumb": {
					width: 20,
					height: 20,
				},
				"& .MuiSwitch-track": {
					borderRadius: 24 / 2,
					backgroundColor: "#E4E7EC",

					opacity: 1,
					transition: opteraTheme.transitions.create(["background-color"], {
						duration: 500,
					}),
				},
			},
		},
		variants: [
			{
				props: { size: "small" },
				style: {
					width: 36,
					height: 20,
					"& .MuiSwitch-thumb": {
						width: 16,
						height: 16,
					},
					"& .MuiSwitch-switchBase": {
						"&.Mui-checked": {
							transform: "translateX(16px)",
						},
					},
				},
			},
		],
	},
	MuiTextField: {
		styleOverrides: {
			root: {
				minWidth: "220px",
				"& .MuiInputBase-input:is(:-webkit-autofill, :autofill)": {
					boxShadow: "none",
					color: opteraTheme.palette.text.primary,
					caretColor: opteraTheme.palette.text.primary,
					WebkitBoxShadow: "0 0 0 100px #FFFFF inset",
					WebkitTextFillColor: opteraTheme.palette.text.primary,
				},
			},
		},
	},
	MuiInputLabel: {
		variants: [
			{
				props: { error: true, disabled: false },
				style: {
					color: `${opteraTheme.palette.error.main}`,
				},
			},
			{
				props: { error: true, disabled: false },
				style: {
					color: `${opteraTheme.palette.error.main}`,
				},
			},
			{
				props: { disabled: true },
				style: {
					color: `${opteraTheme.palette.text.disabled} !important`,
				},
			},
		],
	},
	MuiSkeleton: {
		styleOverrides: {
			root: {
				borderRadius: 8,
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: {
				"&:hover": {
					backgroundColor: opteraTheme.palette.grey[50],
					color: opteraTheme.palette.primary.main,
				},
			},
		},
	},
	MuiTableContainer: {
		styleOverrides: {
			root: {
				border: "1px solid rgba(0, 0, 0, 0.23)",
				boxSizing: "border-box",
				borderRadius: 8,
			},
		},
	},
	MuiDataGrid: {
		defaultProps: {
			autoHeight: true,
			disableColumnMenu: true,
			// disableRowSelectionOnClick: true,
			pageSizeOptions: [10, 25, 50, 100],
			sortingOrder: ["desc", "asc"],
		},
		styleOverrides: {
			root: {
				//  set overflow hidden to fix corners of border being chopped off in header
				overflow: "hidden",
				border: "unset",
				/* Get rid of second bottom border */
				"& div div div div >.MuiTableCell-root": {
					border: "none",
				},
				"& .MuiDataGrid-columnHeader": {
					backgroundColor: opteraTheme.palette.opteraBackground.lightGrey,
				},
				"& .MuiDataGrid-footerContainer": {
					backgroundColor: opteraTheme.palette.opteraBackground.lightGrey,
				},
				"& .MuiDataGrid-columnHeader:focus": {
					outline: "solid rgba(0, 0, 0, 0.18) 1px",
				},
				"& .MuiDataGrid-row.Mui-hover": {
					backgroundColor: opteraTheme.palette.action.hover,
				},
				"& .MuiDataGrid-row.Mui-selected": {
					backgroundColor: opteraTheme.palette.primary[100],
				},
				"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
					outline: "none !important",
				},
				"& .errorCell": {
					backgroundColor: "var(--oc-palette-error-50)",
					color: "var(--oc-palette-error-600)",
					border: "none",
				},
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				"&.Mui-selected": {
					backgroundColor:
						opteraTheme.palette.menuItemSelectedBackground.secondary,
				},
				"&:hover": {
					backgroundColor:
						opteraTheme.palette.menuItemSelectedBackground.secondary,
				},
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				"&.Mui-focused": {
					borderColor: opteraTheme.palette.primary.dark,
				},
				"& > fieldset": {
					borderColor: inactive,
				},
			},
			input: {
				color: opteraTheme.palette.text.primary,
			},
		},

		variants: [
			{
				props: { disabled: true },
				style: {
					borderColor: opteraTheme.palette.action.disabledBackground,
				},
			},
		],
	},
	MuiMenu: {
		styleOverrides: {
			root: {
				maxHeight: "324px",
			},
		},
	},
	MuiFab: {
		styleOverrides: {
			root: {
				backgroundColor: opteraTheme.palette.secondary.dark,
				color: "#FFF",
				":hover": {
					backgroundColor: opteraTheme.palette.secondary.dark,
					color: "#FFF",
				},
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorWarning: {
				backgroundColor: opteraTheme.palette.warning.dark,
			},
		},
	},

	MuiAutocomplete: {
		styleOverrides: {
			option: {
				backgroundColor: opteraTheme.palette.opteraBackground.main,
				"&.MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
					backgroundColor:
						opteraTheme.palette.menuItemSelectedBackground.secondary,
				},
				"&.MuiAutocomplete-option[aria-selected='true']": {
					backgroundColor:
						opteraTheme.palette.menuItemSelectedBackground.secondary,
				},
				"&.Mui-focused.MuiAutocomplete-option": {
					backgroundColor:
						opteraTheme.palette.menuItemSelectedBackground.secondary,
				},
				"&.MuiAutocomplete-option": {
					display: "block",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				},
			},
		},
	},
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				backgroundColor: opteraTheme.palette.tooltip.main,
				color: opteraTheme.palette.tooltip.contrastText,
				borderRadius: "4px",
				fontSize: "14px",
				maxWidth: 336,
				maxHeight: 100,
			},
			arrow: {
				color: opteraTheme.palette.tooltip.main,
			},
		},
	},
	MuiSnackbar: {
		styleOverrides: {
			root: {
				bottom: "32px !important", //  MUI media query overrides to 24px
				minWidth: "320px",
				maxWidth: "600px",
			},
		},
	},
};

export default opteraTheme;
