import { NextPage } from "next";
import { useRouter } from "next/router";

import { Grid, Stack } from "@mui/material";

import AddchartIcon from "@mui/icons-material/Addchart";
import PlaceIcon from "@mui/icons-material/Place";
import GlobeIcon from "@mui/icons-material/Public";

import { useStore } from "@nanostores/react";
import FeaturedContentCard from "common/components/FeaturedContentCard";
import NavCard from "common/components/NavCard";
import PageHeader from "common/components/PageHeader";
import OpteraLayout from "common/layouts/OpteraLayout";
import { user } from "common/stores/UserStore";
import HandsShow from "public/images/HandsShow.svg";

const HOME_TITLE = "Home";
const HOME_GREETING =
	"Scope 3 emissions are the result of activities from entities or assets not owned or operated by your organization, but your organization still influences these activities throughout its value chain.";

const CONTENT_CARD_TITLE =
	"Achieve carbon neutrality across everything your organization impacts.";
const CONTENT_CARD_TEXT =
	"Welcome to Optera. Whether you are a sustainability expert or relatively new to the field, we will guide you through carbon accounting, ESG reporting, and achieving net zero emissions.";
const CONTENT_CARD_BUTTON_TEXT = "Start with Recommended Section";

const SECTION_HEADER_TITLE = "Emissions Quick Links";

const Home: NextPage = () => {
	const $user = useStore(user);
	const router = useRouter();

	if (!$user.username) {
		return null;
	}

	const shouldRedirectFlagstaff = !$user.endorsements
		.toString()
		.includes("survey");

	const shouldRedirectSurvey = $user.endorsements.toString().includes("survey");

	const scmv2Access = $user.endorsements.includes(
		"supply_chain_insights:brand"
	);
	const esgInsightsAccess = $user.endorsements.includes("esg_insights:brand");

	if (scmv2Access) {
		router.push("/supplyChainManager");
		return null;
	}

	if (shouldRedirectFlagstaff) {
		router.push("/legacyOverview");
		return null;
	}

	if (shouldRedirectSurvey) {
		if (
			($user.endorsements.includes("survey:surveyor") ||
				$user.roles.includes("survey:surveyor")) &&
			!Object.keys($user.traits || {}).includes("survey:surveyee")
		) {
			router.push("/survey/surveyProgressDashboard");
		} else {
			router.push("/survey/campaign-select");
		}
		return null;
	}

	if (esgInsightsAccess) {
		router.push("/esgInsights/ng");
		return null;
	}

	// assuming many more survey users than others in the short term. this is a pretty blunt check, but effective for now
	const defaultRecommendedPage = $user.roles.toString().includes("survey")
		? "/survey"
		: "/legacyOverview";
	return (
		<OpteraLayout>
			<Stack spacing={3} mx={7}>
				<PageHeader pageTitle={HOME_TITLE} pageGreeting={HOME_GREETING} />
				<FeaturedContentCard
					title={CONTENT_CARD_TITLE}
					image={HandsShow}
					text={CONTENT_CARD_TEXT}
					buttonLabel={CONTENT_CARD_BUTTON_TEXT}
					buttonIcon={<AddchartIcon />}
					buttonHref={defaultRecommendedPage}
					wide
				/>
				<Stack
					spacing={2}
					sx={{
						pt: 9,
					}}
				>
					<h5 className="bold">{SECTION_HEADER_TITLE}</h5>
					<hr />
				</Stack>
				<div>
					<Grid container>
						<Grid item md={12} lg={6}>
							<NavCard
								label="Scope 1 & 2 Emissions"
								content="The emissions generated from the operation of your business. Access feature on our classic product: Open Classic Optera"
								icon={<PlaceIcon />}
							/>
						</Grid>
						<Grid item md={12} lg={6}>
							<NavCard
								label="Scope 3 Emissions"
								content="Upstream emissions generated from the purchase of capital goods and equipment like building, machinery, and tools."
								icon={<GlobeIcon />}
								href="/"
							/>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</OpteraLayout>
	);
};

export default Home;
