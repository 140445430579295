import { prepareSearchParams } from "modules/facade/EnergieClient";
import Router from "next/router";

/**
 *
 * @param endpoint
 * @param successRedirect page to redirect to if login succeeds, relative to index (e.g. "/" for index, "/FooPage" for FooPage)
 * @param failRedirect page to redirect to if login fails, relative to index
 * @param username potentially used to pre-populate login form
 */
export async function loginRedirect(
	endpoint: string,
	successRedirect?: string,
	failRedirect?: string,
	username?: string
) {
	// Disable destructuring rule as process.env can't be destructured
	// see: https://nextjs.org/docs/api-reference/next.config.js/environment-variables
	/* eslint-disable prefer-destructuring */
	const MONARCH_URL = process.env.MONARCH_URL;
	const BASE_FE_URL = process.env.BASE_FE_URL;
	/* eslint-enable prefer-destructuring */

	// actionURL looks something like "https://app1.dev.opteraclimate.com/facade" + "/auth/" + "auth0/login"
	const actionURL = `${MONARCH_URL}/auth/${endpoint}`;
	const searchParams = prepareSearchParams({
		redirect_success: BASE_FE_URL + (successRedirect || "/"),
		redirect_fail: BASE_FE_URL + (failRedirect || "/"),
		username,
	});
	const fullURL = `${actionURL}?${searchParams.toString()}`;
	Router.push(fullURL);
}

/**
 * Same as above, but uses logout redirect urls directly, rather than applying the base url
 * @param endpoint
 * @param successRedirect
 * @param failRedirect
 */
export async function logoutRedirect(
	endpoint: string,
	successRedirect: string,
	failRedirect: string
) {
	// Disable destructuring rule as process.env can't be destructured
	// see: https://nextjs.org/docs/api-reference/next.config.js/environment-variables
	/* eslint-disable prefer-destructuring */
	const MONARCH_URL = process.env.MONARCH_URL;
	const BASE_FE_URL = process.env.BASE_FE_URL;
	const ENABLE_LOCAL_AUTH = process.env.ENABLE_LOCAL_AUTH;
	/* eslint-enable prefer-destructuring */

	let successR = `${BASE_FE_URL}${successRedirect}`;
	let failR = `${BASE_FE_URL}${failRedirect}`;

	// Auth0 can't redirect to localhost post-logout, so if the BASE_FE_URL
	// contains localhost, redirect to monarch api index for now.
	if (BASE_FE_URL?.includes("localhost") && ENABLE_LOCAL_AUTH !== "true") {
		successR = "https://monarch.api.dev.opteraclimate.com/";
		failR = "https://monarch.api.dev.opteraclimate.com/";
	}

	const actionURL = `${MONARCH_URL}/auth/${endpoint}`;
	const fullURL = `${actionURL}?redirect_success=${encodeURIComponent(
		successR
	)}&redirect_fail=${encodeURIComponent(failR)}`;
	Router.push(fullURL);
}

export default {};
